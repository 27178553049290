<template>
  <v-row>
    <v-col cols="12" md="3">
      <div>
        <EstadoBtn
          :estado="estudio.estado_actual || {}"
          :interno="0"
          :idEstudio="estudio.idEstudio"
          :fechaCambio="
            (estudio.cronograma.find((e) => e.interno == 0) || {}).fechaCambio
          "
          @reload="$emit('reload')"
          :no-click="!$root.acceso('EDITAR_ESTUDIOS')"
        />
        <div class="my-4"></div>

        <AuxInput title="Instalación">
          <div class="d-flex  flex-wrap align-center">
            <EstadoBtn
              v-if="$root.acceso('EDITAR_ESTUDIOS')"
              :estado="estudio?.instalacion?.estado_actual_int || {}"
              tipo="instalacion"
              :idEstudio="estudio.idEstudio"
              :fechaCambio="
                (estudio.cronograma.find((e) => e.interno == 1) || {})
                  .fechaCambio
              "
              @reload="$emit('reload')"
            />
            <v-btn v-if="estudio?.instalacion" @click.stop="deleteInstalacion" icon color="error"> <v-icon> mdi-delete </v-icon></v-btn>
          </div>
        </AuxInput>

        <AuxInput title="Cargador VE">
          <div class="d-flex flex-wrap  align-center">
            <EstadoBtn
              v-if="$root.acceso('EDITAR_ESTUDIOS')"
              :estado="estudio?.cargador?.estado_actual_int || {}"
              tipo="cargador"
              :idEstudio="estudio.idEstudio"
              :fechaCambio="
                (estudio.cronograma.find((e) => e.interno == 1) || {})
                  .fechaCambio
              "
              @reload="$emit('reload')"
            /><v-btn v-if="estudio?.cargador" @click.stop="deleteCargador" icon color="error"> <v-icon> mdi-delete </v-icon></v-btn>
          </div>
        </AuxInput>

        <div class="my-4"></div>
        <v-dialog v-model="cambios.interes" max-width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              block
              outlined
              :color="estudio?.interes?.color"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon left>{{ estudio?.interes?.icon }}</v-icon>
              {{ estudio?.interes?.nombre || "Nivel de interés" }}
            </v-btn>
          </template>
          <InteresEstudio
            @reload="$emit('reload')"
            @close="cambios.interes = false"
            v-model="estudio.interes"
            :estudio="estudio.idEstudio"
          />
        </v-dialog>
      </div>

      <v-divider class="mt-4" />
      <aux-input title="Estudio">
        {{ estudio.idEstudio }}
      </aux-input>
      <v-divider />
      <aux-input title="Nombre proyecto">
        {{ estudio.nombreProyecto }}
      </aux-input>
      <v-divider />
      <aux-input title="Tipo estudio">
        {{ estudio.tipo.nombreTipo }}
      </aux-input>
      <v-divider />
      <template v-if="$root.acceso('EDITAR_ESTUDIOS')">
        <aux-input title="Comercial">
          {{
            [estudio.comercial, estudio.subComercial]
              .filter((x) => x)
              .join(" | ")
          }}
        </aux-input>
        <v-divider class="mb-1" />
        <v-divider />
        <aux-input title="Potencia estudio">
          {{ estudio.potenciaEstudio }}
        </aux-input>
        <v-divider></v-divider>
        <aux-input title="Precio Pico">
          {{ estudio.precioPico }}
        </aux-input>
        <v-divider></v-divider>
        <aux-input title="Precio Total">
          {{ estudio.precioTotal }}
        </aux-input>
        <v-divider class="mb-1"></v-divider>
      </template>
    </v-col>
    <v-col cols="12" md="9">
      <tarjeta-datos header icon="playlist-plus">
        <template v-slot:titulo>
          Mensajes estudio
          <v-btn
            class="ml-auto"
            small
            color="primary"
            @click.stop="$refs.chat.getMessages"
          >
            <v-icon left>mdi-refresh</v-icon>Recargar
          </v-btn>
        </template>

        <Chat
          ref="chat"
          :idEstudio="estudio.idEstudio"
          @reload="$emit('reload')"
        />
      </tarjeta-datos>
    </v-col>

    <v-col v-if="$root.acceso('EDITAR_ESTUDIOS')" cols="12" md="5">
      <div class="d-flex align-center" style="gap: 10px">
        <v-subheader>Cronograma</v-subheader>
        <v-chip-group v-model="filterNotas" active-class="primary--text" column>
          <v-chip label value="General">General</v-chip>
          <v-chip label value="Interno">Interno</v-chip>
          <v-chip label value="Notas">Notas</v-chip>
          <v-chip label value="Contactos">Contactos</v-chip>
        </v-chip-group>
        <v-btn @click="descargarCronograma" small color="primary" text>
          <v-icon left>mdi-download</v-icon>
          Descargar .CSV
        </v-btn>
      </div>

      <cronograma-estudio
        :key="filterNotas"
        :idEstudio="estudio.idEstudio"
        :filter-notas="filterNotas"
        :cronograma="estudio.cronograma"
        @reload="$emit('reload')"
      />
    </v-col>

    <v-col v-if="false && $root.acceso('EDITAR_ESTUDIOS')" cols="12" md="6">
      <v-subheader>Alertas</v-subheader>
      <div class="d-flex mt-n6 align-center">
        <v-spacer></v-spacer>
        <v-alert type="info" text dense class="ma-0"
          >{{
            estudio.cronograma.filter(
              (n) => n.tipo == "Alerta" && n.enviado == 0
            ).length
          }}
          alerta(s) activas</v-alert
        >

        <v-dialog max-width="700" v-model="addAlertDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              class="ml-2"
              @click.stop="addAlertDialog = true"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon left>mdi-bell-plus-outline</v-icon>
              Añadir alerta
            </v-btn>
          </template>

          <CrearAlerta
            :key="addAlertDialog"
            :idEstudio="estudio.idEstudio"
            @reload="$emit('reload')"
            @close="addAlertDialog = false"
          />
        </v-dialog>
      </div>

      <cronograma-estudio
        :idEstudio="estudio.idEstudio"
        :cronograma="cronograma.alertas"
        @reload="$emit('reload')"
        no-button
      />
    </v-col>

    <v-col
      v-if="
        $root.acceso('EDITAR_ESTUDIOS') ||
        !['Borrador', 'Pendiente Revisión'].includes(
          estudio.estado_actual?.estado
        )
      "
      cols="12"
      md="7"
    >
      <tarjeta-datos
        titulo="Documentación final"
        icon="file-document-edit-outline"
      >
        <DocumentacionEstudio
          :key="keyDocumentacion"
          :idEstudio="estudio.idEstudio"
          sub="final"
        />
        <div class="pa-4">
          <v-dialog max-width="800" v-model="showAutoestudio">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" outlined large v-on="on" v-bind="attrs">
                <v-icon left>mdi-auto-fix</v-icon>AUTOESTUDIO EXPRESS
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dark dense color="primary">
                <v-toolbar-title>Estudio express</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.stop="showAutoestudio = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <EstudioExpress
                :estudio="estudio"
                :idEstudio="estudio.idEstudio"
                @reload="keyDocumentacion++"
                ref="estudioExpress"
                :creatingAutoestudio.sync="creatingAutoestudio"
              />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click.stop="$refs.estudioExpress.estudioExpress()"
                  :loading="creatingAutoestudio"
                >
                  <v-icon left>mdi-auto-fix</v-icon>
                  Generar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </tarjeta-datos>
    </v-col>

    <ConfirmDialog ref="confirm" />
  </v-row>
</template>

<script>
import {
  downloadFile,
  parseDate,
  getSituacionIcon,
  timeAgo,
  jsonToCsv,
  copyText,
} from "@/utils/index.js";

export default {
  props: {
    estudio: Object,
    idEstudio: Number,
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    EstadoBtn: () => import("../EstadoBtn.vue"),
    InstaladorasEstudios: () => import("../InstaladorasEstudios.vue"),
    InteresEstudio: () => import("../InteresEstudio.vue"),
    DocumentacionEstudio: () => import("../documentacion/index"),
    CronogramaEstudio: () => import("@/components/CronogramaEstudio.vue"),
    CrearAlerta: () => import("@/components/CrearAlerta.vue"),
    Chat: () => import("../Chat/Chat.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    EstudioExpress: () => import("../EstudioExpress.vue"),
  },
  data() {
    return {
      filterNotas: null,
      showAutoestudio: false,
      creatingAutoestudio: false,
      cambios: {
        interes: false,
      },
      keyDocumentacion: 0,
    };
  },
  computed: {
    autoestudioMissing() {
      return [
        { nombre: "CUPS", value: this.estudio.cups },
        { nombre: "Inclinación", value: this.estudio.inclinacion },
        { nombre: "Azimut", value: this.estudio.azimut },
        { nombre: "Latitud", value: this.estudio.latitud },
        { nombre: "Longitud", value: this.estudio.longitud },
      ].filter((x) => x.value == null);
    },
  },
  methods: {
    downloadFile,
    parseDate,
    getSituacionIcon,
    timeAgo,
    jsonToCsv,
    copyText,
    async descargarCronograma() {
      const csvHeaders = [
        { key: "usuario" },
        { key: "apagado" },
        { key: "enviado" },
        { key: "fecha", dataType: "date" },
        { key: "resumen" },
        { key: "texto" },
        { key: "tipo" },
      ];

      jsonToCsv(
        this.cronograma.notas,
        csvHeaders,
        `cronograma_estudio_${this.estudio.idEstudio}_${Date.now()}.csv`
      );
    },
    async deleteInstalacion() {
      await axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_API_URL}/instalaciones/${ this.estudio.instalacion.idEstudio }`
      })
      this.$emit('reload')
    },
    async deleteCargador() {
      await axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_API_URL}/cargadores/${ this.estudio.cargador.idEstudio }`
      })
      this.$emit('reload')
    }
  },
};
</script>

<style>
.item-selection {
  display: grid !important;
  grid-template-rows: 1fr 0fr;
  text-align: center;
}

.v-item--active {
  color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}
</style>
